import { createSlice } from '@reduxjs/toolkit';

const defaultReplayState = {
  index: 0,
  playing: false,
  positions: [],
  onReplayPage: false,
  pause: false,
};

const { reducer, actions } = createSlice({
  name: 'replay',
  initialState: {
    ...defaultReplayState,
    userEnabledNotifications: [],
    recenterTime: new Date(),
  },
  reducers: {
    setPositions(state, action) {
      state.positions = action.payload;
    },
    resetPositions(state) {
      state.positions = [];
    },
    setPlaying(state, action) {
      state.playing = action.payload;
    },
    resetPlaying(state) {
      state.playing = false;
    },
    setIndex(state, action) {
      state.index = action.payload;
    },
    resetIndex(state) {
      state.index = 0;
    },
    setOnReplayPage(state, action) {
      state.onReplayPage = action.payload;
    },
    setPause(state, action) {
      state.pause = action.payload;
    },
    resetPause(state) {
      state.pause = false;
    },
    setUserEnabledNotifications(state, action) {
      state.userEnabledNotifications = action.payload;
    },
    resetReplayToDefault(state) {
      Object.assign(state, defaultReplayState);
    },
    updateRecenterTime(state) {
      state.recenterTime = new Date();
    },
  },
});

export { actions as replayActions };
export { reducer as replayReducer };
