import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useCatch, useEffectAsync } from '../reactHelper';
import customFetch from '../common/util/customFetch';

const useEditItem = ({
  item,
  setItem,
  onItemSaved,
  defaultItem,
  endpoint,
  id,
}) => {
  const { id: paramId } = useParams();
  id = id || paramId;

  const [showSpinner, setShowSpinner] = useState(false);

  useEffectAsync(async () => {
    if (!item) {
      if (id) {
        const response = await customFetch(`/api/${endpoint}/${id}`);
        if (response.ok) {
          setItem(await response.json());
        } else {
          throw Error(await response.text());
        }
      } else {
        setItem(defaultItem || {});
      }
    }
  }, [id, item, defaultItem]);

  const save = useCatch(async (extraData = {}) => {
    setShowSpinner(true);
    let url = `/api/${endpoint}`;
    if (id) {
      url += `/${id}`;
    }

    const itemsToSave = {
      ...item,
      ...extraData,
      administrator: (item.administrator || item.superAdministrator),
    };
    const response = await customFetch(url, {
      method: !id ? 'POST' : 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(itemsToSave),
    });

    setShowSpinner(false);
    if (response.ok) {
      if (onItemSaved) {
        onItemSaved(await response.json());
      }
    } else {
      throw Error(await response.text());
    }
  });

  return { save, saving: showSpinner };
};

export default useEditItem;
