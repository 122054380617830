import React, { useState } from 'react';
import MuiSelect from './MuiSelect';
import { useEffectAsync } from '../../reactHelper';
import customFetch from '../util/customFetch';

const TimeZone = ({
  onChangeHandler,
  required = false,
  filter,
  value,
}) => {
  const [timeZones, setTimeZones] = useState([]);

  useEffectAsync(async () => {
    const filterParams = new URLSearchParams(filter);
    const response = await customFetch(`/api/server/timezones?${filterParams.toString()}`);
    if (response.ok) {
      const newTimeZones = await response.json();
      if (newTimeZones.findIndex((zone) => zone.id === value) > -1) {
        onChangeHandler(value);
      }
      setTimeZones(newTimeZones);
    }
  }, [filter]);
  return (
    <MuiSelect
      id="time zone"
      value={value}
      title="Time Zone"
      options={timeZones.map((timezone) => ({
        label: timezone.name,
        id: timezone.id,
      }))}
      require={required}
      onChangeHandler={onChangeHandler}
    />
  );
};

export default TimeZone;
