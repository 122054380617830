import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactFlagsSelect from 'react-flags-select';
import AlertMessage from '../../common/components/AlertMessage';
import TimeZone from '../../common/components/TimeZone';
import { sessionActions } from '../../store';
import useEditItem from '../../hooks/useEditItem';
import { RESTRICTED_COUNTRY_CODES } from '../../constants';

const Preferences = () => {
  const [success, setSuccess] = useState(false);
  const currentUser = useSelector((state) => state.session.user);
  const [item, setItem] = useState(currentUser);
  const [errors, setErrors] = useState({});
  const updateAttribute = useCallback((key, value) => {
    if (!value) {
      setErrors((errors) => ({
        ...errors,
        [key]: `${key} is required`,
      }));
    } else {
      setItem((item) => ({
        ...item,
        attributes: {
          ...item.attributes,
          [key]: value,
        },
      }));
      setErrors((errors) => ({
        ...errors,
        [key]: '',
      }));
    }
  }, [setItem, setErrors]);
  const dispatch = useDispatch();

  const onItemSaved = (result) => {
    setSuccess(true);
    if (result.id === currentUser.id) {
      dispatch(sessionActions.updateUser(result));
    }
  };

  const { save, saving } = useEditItem({
    endpoint: 'users',
    item,
    setItem,
    onItemSaved,
    id: currentUser.id,
  });

  const timezoneFilter = useMemo(
    () => ({
      country: item?.attributes?.country,
    }),
    [item?.attributes?.country],
  );

  useEffect(() => {
    if (item?.attributes?.country) {
      updateAttribute('timezone', '');
    }
  }, [item?.attributes?.country, updateAttribute]);

  return (
    <div className="settings-block">
      <div className="settings-block__header">
        <h4 className="settings-block__header-title">Preferences</h4>
      </div>
      <div className="settings-block__body profile-settings-form region-form">
        <div className="country-dropdown">
          <label>
            Country
            <span className="info-required">*</span>
          </label>
          <ReactFlagsSelect
            className="country-select-field"
            selected={item?.attributes?.country}
            countries={RESTRICTED_COUNTRY_CODES}
            blacklistCountries
            searchable
            onSelect={(code) => updateAttribute('country', code)}
            placeholder={
              <div className="select-placeholder-text">Select Country</div>
            }
          />
        </div>
        <TimeZone
          onChangeHandler={(value) => updateAttribute('timezone', value)}
          required
          filter={timezoneFilter}
          value={item?.attributes?.timezone}
        />

        <FormControl className="radiobox-form">
          <FormLabel id="demo-row-radio-buttons-group-label">
            <FormLabel id="demo-row-radio-buttons-group-label">
              Speed
              <span className="info-required">*</span>
            </FormLabel>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={item.attributes?.speedUnit}
            onChange={(event) => updateAttribute('speedUnit', event.target.value)}
          >
            <FormControlLabel
              value="mph"
              control={(
                <Radio
                  icon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <circle cx="7.5" cy="8" r="7" stroke="#8899B4" />
                    </svg>
                  )}
                  checkedIcon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="7.5" stroke="#8899B4" />
                      <circle cx="8" cy="8" r="6" fill="#1A74E2" />
                    </svg>
                  )}
                />
              )}
              label="Mph"
            />
            <FormControlLabel
              value="kmh"
              control={(
                <Radio
                  icon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <circle cx="7.5" cy="8" r="7" stroke="#8899B4" />
                    </svg>
                  )}
                  checkedIcon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="7.5" stroke="#8899B4" />
                      <circle cx="8" cy="8" r="6" fill="#1A74E2" />
                    </svg>
                  )}
                />
              )}
              label="Kph"
            />
          </RadioGroup>
        </FormControl>
        <FormControl className="radiobox-form">
          <FormLabel id="demo-row-radio-buttons-group-label">
            Temperature
            <span className="info-required">*</span>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={item.attributes?.temperatureUnit}
            onChange={(event) => updateAttribute('temperatureUnit', event.target.value)}
          >
            <FormControlLabel
              value="f"
              control={(
                <Radio
                  icon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <circle cx="7.5" cy="8" r="7" stroke="#8899B4" />
                    </svg>
                  )}
                  checkedIcon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="7.5" stroke="#8899B4" />
                      <circle cx="8" cy="8" r="6" fill="#1A74E2" />
                    </svg>
                  )}
                />
              )}
              label="Fahrenheit"
            />
            <FormControlLabel
              value="c"
              control={(
                <Radio
                  icon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <circle cx="7.5" cy="8" r="7" stroke="#8899B4" />
                    </svg>
                  )}
                  checkedIcon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="7.5" stroke="#8899B4" />
                      <circle cx="8" cy="8" r="6" fill="#1A74E2" />
                    </svg>
                  )}
                />
              )}
              label="Celsius"
            />
          </RadioGroup>
        </FormControl>
        <FormControl className="radiobox-form">
          <FormLabel id="demo-row-radio-buttons-group-label">
            <FormLabel id="demo-row-radio-buttons-group-label">
              Distance
              <span className="info-required">*</span>
            </FormLabel>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={item.attributes?.distanceUnit}
            onChange={(event) => updateAttribute('distanceUnit', event.target.value)}
          >
            <FormControlLabel
              value="mi"
              control={(
                <Radio
                  icon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <circle cx="7.5" cy="8" r="7" stroke="#8899B4" />
                    </svg>
                  )}
                  checkedIcon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="7.5" stroke="#8899B4" />
                      <circle cx="8" cy="8" r="6" fill="#1A74E2" />
                    </svg>
                  )}
                />
              )}
              label="Miles"
            />
            <FormControlLabel
              value="km"
              control={(
                <Radio
                  icon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <circle cx="7.5" cy="8" r="7" stroke="#8899B4" />
                    </svg>
                  )}
                  checkedIcon={(
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="7.5" stroke="#8899B4" />
                      <circle cx="8" cy="8" r="6" fill="#1A74E2" />
                    </svg>
                  )}
                />
              )}
              label="Kilometer"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="settings-block__footer">
        <Button
          variant="contained"
          className="settings-block__content--right btn btn-contained"
          color="primary"
          onClick={() => save()}
          disabled={
            saving ||
            !item.attributes?.speedUnit ||
            !item.attributes?.distanceUnit ||
            !item.attributes?.temperatureUnit ||
            !item.attributes?.country ||
            !item.attributes?.timezone ||
            Object.values(errors).some(Boolean)
          }
        >
          Save Changes
        </Button>
      </div>
      <AlertMessage
        show={success}
        onClose={() => setSuccess(false)}
        vertical="bottom"
        duration={3000}
        message="User preferences updated"
      />
    </div>
  );
};

export default Preferences;
