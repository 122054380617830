import React, { useEffect } from 'react';

import {
  Autocomplete,
  TextField,
} from '@mui/material';

const MuiSelect = ({
  id,
  title,
  options,
  require,
  onChangeHandler,
  value,
}) => {
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    setInputValue(options.find((option) => option.id === value)?.label || '');
  }, [options, value]);

  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      onInputChange={(e, v) => {
        if (!v || v !== value) setInputValue(v);
      }}
      className="time-zone-field"
      id={id}
      size="medium"
      onChange={(e, v) => { if (!v) return; setInputValue(v?.label || ''); onChangeHandler(v?.id); }}
      options={options}
      componentsProps={{
        paper: {
          sx: {
            fontSize: '14px',
            backgroundColor: '#ffffff',
            color: '#021021 !important',
            fontWeight: '500',
            fontFamily: 'inherit',
          },
        },
      }}
      renderInput={(props) => (
        <TextField
          {...props}
          label={title}
          className="form-textfield"
          required={require}
          placeholder="(UTC -10:00) Adak, United States"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export default MuiSelect;
